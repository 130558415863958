import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon  } from '@heroicons/react/outline'
import { useAuth } from '../../../contexts/Auth'
import Image from 'next/image'
import Link from 'next/link'
import { loadIntercom } from 'next-intercom'
import { useEffect } from 'react'
import { dev } from '../../../config'
import Cookies from "../../Cookies"
import { useState } from 'react'
import { setCookie, getCookie} from 'cookies-next';

const navigation = [
  // { name: 'Product', href: '#' },
  { name: 'AI Generator', href: '/ai-tools' },
  { name: 'Directory', href: '/directory' },
  { name: 'Guides', href: '/guides'},
  { name: 'Blog', href: '/blog' },
]


export default function HomeNav () {
    const { user, signOut } = useAuth();
    const [showPolicy, setShowPolicy] = useState(false)

    useEffect(()=> {
      if (!dev && user) {
          loadIntercom({
              appId: 'zpntjk45', // default : ''
              name: "", //default: RandomName
              email: user ? user.email : "",
              ssr: false, // default: false
              initWindow: true, // default: true
              delay: 2000, // default: 0  - useful for mobile devices to prevent blocking the main thread
            });
      } else if (!dev) {
        loadIntercom({
          appId: 'zpntjk45', // default : ''
          ssr: false, // default: false
          initWindow: true, // default: true
          delay: 2000, // default: 0  - useful for mobile devices to prevent blocking the main thread
        });
      }
     
      const cook = getCookie("policy")
      if (!cook) {
        setShowPolicy(true)
        setCookie("policy","true")
      } 
         
    },[user])
    
    return (
        <Popover as="header" className="relative">
          <Cookies showPolicy={showPolicy} setShowPolicy={setShowPolicy} />
        <div className="bg-slate-200/50 pt-6">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1 justify-between">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link href="/">
                <a >
                  <span className="sr-only">Plaza</span>
                  <Image 
                      alt="Plaza Logo" 
                      src="https://d28zq0um2frnpx.cloudfront.net/static/plaza-logto-tail.png" 
                      width={128} height={64} 
                      objectFit="contain"
                  />
                </a>
                </Link>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:!flex md:ml-10 md:mr-10">
                {navigation.map((item) => (
                    <Link key={item.name} href={item.href}>
                      <a  className="text-base font-medium text-slate-500 hover:text-gray-800">
                    {item.name}
                  </a></Link>
                
                ))}
              </div>
            </div>
            {user ?
                   <div className="hidden md:!flex  md:items-center md:space-x-8">
                                  <div className="text-center  text-base font-medium text-gray-600">
         
             
         <button onClick={()=> signOut()} className="text-gray-900 hover:underline">
           Logout
         </button>
    
       </div>

                    <Link href="/dashboard">
                      <a
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-fuchsia-800 text-white shadow-lg shadow-fuchsia-500/50 bg-fuchsia-700"
                      >
                        Dashboard
                      </a>
                    </Link>

                   </div>
                  :
                  <div className="hidden  md:!flex md:items-center md:space-x-6">
                  <Link href="/login">
                  <a className="text-base font-medium text-slate-900 hover:text-gray-600">
                    Log in
                  </a>
                  </Link>
                  <Link href="/#bookDemo">
                  <a
                    className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md hover:bg-fuchsia-800 text-white shadow-lg shadow-fuchsia-500/50 bg-fuchsia-700"
                  >
                    Book a Demo
                  </a>
                  </Link>
                </div>
                  
                  }

          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                <Image 
                      alt="Plaza" 
                      src="https://d28zq0um2frnpx.cloudfront.net/static/plaza-logto-tail.png" 
                      width={128} height={64} 
                      objectFit="contain"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fuchsia-600">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link  key={item.name} href={item.href}>
                     <a
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                     >
                      {item.name}
                    </a>
                    </Link>
                   
                  ))}
                </div>

                <div className="mt-6 px-5">
                  {user ? 
                  <>
                   <Link href="/dashboard">
                   <a
                     className="block text-center w-full py-3 px-4 rounded-md shadow shadow-fuchsia-500/50 bg-fuchsia-700 text-white font-medium hover:bg-fuchsia-900"
                   >
                   Dashboard
                   </a>
                 </Link>
                    <div className="text-center mt-4 text-base font-medium text-gray-600">
         
             
                    <button onClick={()=> signOut()} className="text-gray-900 hover:underline">
                      Logout
                    </button>
               
                  </div>
                  </>
                :
                <>
                <div className="mt-6 px-5">
                <Link href="/#bookDemo">
                  <a
                    className="block text-center w-full py-3 px-4 rounded-md shadow shadow-fuchsia-500/50 bg-fuchsia-700 text-white font-medium hover:bg-fuchsia-900"
                  >
                  Book a Demo
                  </a>
                </Link>
              </div>
                <p className="text-center text-base font-medium text-gray-600">
                Existing customer?{' '}
                <Link  href="/login">
                <a className="text-gray-900 hover:underline">
                  Login
                </a>
                </Link>
              </p>
              </>
                }
                 
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

    )
}




