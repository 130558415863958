import React from 'react';
import { X } from 'react-bootstrap-icons';
import Link from 'next/link'

export default function Cookies(props) {

    if (props.showPolicy) {
        return (
            <div 
                id="cookies" 
                className="fixed w-full bottom-0 z-50 p-3 mb-0 bg-slate-400 flex justify-center" 
            >
                <X onClick={() => props.setShowPolicy(false)} className="mx-3 text-white hover:text-slate-300" style={{ fontSize:25}}/>
                <p className='text-white text-center mb-0'>
                   By using this site, you agree to our 
                   <u>
                        <Link href="/cookie-policy" prefetch={false}>
                            <a className="text-white"> Cookie Policy </a>
                        </Link>
                    </u>
                     and 
                     <u>
                         <Link href="/privacy-policy" prefetch={false}><a className="text-white"> Privacy Policy</a></Link>
                    </u>
                 </p>
                 
            </div>
        );
    }
    return null
}
