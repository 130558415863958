import Navigation from './Navbars/HomeNav'
import dynamic from 'next/dynamic'

const Footer = dynamic(() => import('../Footer'))

export default function Layout({children}) {
    return (
    <>
            <Navigation />
              {children}
        {/* <Footer /> */}
    </>
    )
}