import Head from 'next/head'

//Could use a package called next-seo
export default function Meta({title, description, image, url}) {
    return (
        <>
            <Head>
                <meta name='viewport' content='width=device-width, initial-scale=1' />
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta charSet="utf-8" />
                <link rel='icon' href='/favicon.ico' />
                {url && 
                <>
                    <link
                        rel="canonical"
                        href={url}
                        key="canonical"
                    />
                    <meta property="og:url" content={url} />
                </>
                }
                <meta property="og:type" content="website" /> 
                <meta property="og:site_name" content='https://plaza.so/' />
                {image &&
                    <meta property="og:image" content={image} />
                }
                <meta property="og:description" content={description} />
                <meta property="og:title" content={title} />


        
                
            </Head>

       </>
    )
}